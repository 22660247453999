<template>
  <div>
    <hero></hero>
    <analytics></analytics>
    <video-player2></video-player2>
    <tour4></tour4> 
      <reports2></reports2>
    <l-footer></l-footer>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>